import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { SectionHeading } from '@components/Common';
import { Layout, Container } from 'layouts';
import TechStack from '@constants/iosTechStack';

// Assets
import headerImage from '@assets/services/ios-dev/ios-header.jpg';

import AppServiceIcon from '@assets/services/ios-dev/app-service.svg';
import CustomSolutionIcon from '@assets/services/ios-dev/custom-solution.svg';
import EnterpriseSolutionIcon from '@assets/services/ios-dev/enterprise-solution.svg';
import UIUXDesignIcon from '@assets/services/ios-dev/ui-ux-design.svg';
import WidgetDevelopmentIcon from '@assets/services/ios-dev/widget-development.svg';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 30px;
`;

const IconElem = styled.div`
  width: 200px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: start;
  flex-flow: column;
  margin: 15px 20px;
  .process-section {
    width: 200px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    width: 180px;
  }
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const ProcessWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.primary.lighter};
`;

const ProcessIconElem = styled(IconElem)`
  width: 150px;
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    width: 90px;
  }
  svg {
    margin-bottom: 20px;
  }
`;

const PointsWrapper = styled.div`
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  margin-top: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    width: 100%;
  }
`;

const Point = styled.div`
  font-size: 14px;
  margin: 5px;
  line-height: 1.5;
  &::before {
    content: ' ';
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-right: 15px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary.base};
  }
`;

const TechWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TechItem = styled.div`
  font-size: 14px;
  width: 150px;
  margin: 10px;
`;

const TechHeading = styled.p`
  font-weight: 600;
  margin: 0 0 20px 0;
`;

const Techs = styled.p`
  margin: 0;
  font-weight: 500;
`;

const IOSDevelopement = () => {
  return (
    <Layout>
      <Helmet title={'iOS'} />
      <PageHero image={headerImage} />
      <Heading title="iOS Application Development" noHeadingIcon={true}>
        <p>We leverage the strengths of the iOS platform to build incredible solutions across iPhone, iPad, Apple Watch and Apple TV.</p>
        <p>
          iOS Apps need special attention to the minutest of UI/UX details and SDK usage to ensure that it conforms to the standards set by Apple. We
          at CodeMyMobile have the best team of experienced iOS App developers who have developed apps from iPhone to iPad to Apple Watch for a
          multitude of clients spread across various industries.
        </p>
        <p>
          We have a bank of experienced Swift as well as Objective C developers who have developed world-class applications widely used with active
          users that adhere to the best practises by Apple and the iOS community.
        </p>
      </Heading>
      <Container>
        <SectionHeading>Why us?</SectionHeading>
        <PointsWrapper>
          <Point>Strict Adherence to Apple HIG and iOS SDK usage policy.</Point>
          <Point>Special Attention to User Experience across various device sizes and form factors</Point>
          <Point>Security - All apps that we develop have encrypted data on the device and the server.</Point>
          <Point>World-Class User Interface - UI with the latest animation trends.</Point>
          <Point>Analytics on User behaviour</Point>
          <Point>Targeted Push Notifications and Custom Reports generation on Analytics</Point>
          <Point>User Retention Planning and Optimization</Point>
          <Point>Conversion rate and Marketing Spend Optimization</Point>
        </PointsWrapper>
      </Container>
      <ProcessWrapper>
        <Container>
          <SectionHeading>We provide bespoke, robust and scalable iOS app solutions.</SectionHeading>
          <IconWrapper>
            <ProcessIconElem>
              <CustomSolutionIcon />
              <Label>Custom iPhone app solution</Label>
            </ProcessIconElem>
            <ProcessIconElem>
              <EnterpriseSolutionIcon />
              <Label>Enterprise iPhone app solution</Label>
            </ProcessIconElem>
            <ProcessIconElem>
              <UIUXDesignIcon />
              <Label>iPhone app UI/UX Design</Label>
            </ProcessIconElem>
            <ProcessIconElem>
              <WidgetDevelopmentIcon />
              <Label>iPhone Widget/Extension Development</Label>
            </ProcessIconElem>
            <ProcessIconElem>
              <AppServiceIcon />
              <Label>iPhone app Support, Optimization, and Maintenance support</Label>
            </ProcessIconElem>
          </IconWrapper>
        </Container>
      </ProcessWrapper>
      <Container>
        <SectionHeading>Technology Stack</SectionHeading>
        <TechWrapper>
          {TechStack.map((item, id) => {
            return (
              <TechItem key={id}>
                <TechHeading>{item.type}</TechHeading>
                {item.data.map((lang, idx) => {
                  return <Techs key={idx}>{lang}</Techs>;
                })}
              </TechItem>
            );
          })}
        </TechWrapper>
      </Container>
    </Layout>
  );
};

export default IOSDevelopement;
