const techStack = [
  {
    type: 'Programming languages',
    data: ['Swift', 'Objective C'],
  },
  {
    type: 'Technologies',
    data: ['iOS SDK', 'iOS Native Dev Kit'],
  },
  {
    type: 'Database',
    data: ['SQLite', 'Core Data'],
  },
  {
    type: 'Testing',
    data: ['Simulator', 'Testlabs'],
  },
  {
    type: 'IDE',
    data: ['Xcode'],
  },
  {
    type: 'OS',
    data: ['iOS 8.0-12.4.1-Support'],
  },
  {
    type: 'Design',
    data: ['Flat Design'],
  },
];

export default techStack;
